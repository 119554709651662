import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  sectionHeaderText: {
    color: '#89909B',
    fontSize: 18,
    fontWeight: '500',
    paddingTop: 15,
    paddingBottom: 2,
    paddingHorizontal: 15,
    textTransform: 'capitalize',
    flexDirection: 'column',
  },
  sectionHeaderTextPortrait: {
    color: '#89909B',
    fontSize: 18,
    fontWeight: '500',
    paddingTop: 15,
    paddingBottom: 2,
    paddingHorizontal: 15,
    textTransform: 'capitalize',
    flexDirection: 'row',
  },
});

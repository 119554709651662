import React from 'react';
import {
  TouchableOpacity,
  View,
  Text,
  LayoutRectangle,
  SectionListData,
  ScrollView,
  Dimensions,
  LayoutChangeEvent,
  SectionList,
} from 'react-native';
import { styles } from 'styles/components/customTabBarSectionList';
import {
  TabBarProps,
  CustomTabBarSectionListProps,
  ITabsLayoutRectangle,
} from 'types/components/CustomTabBarSectionListProps';

export default (sectionListData: CustomTabBarSectionListProps): JSX.Element => {
  const scrollViewRef = React.useRef<ScrollView>(null);
  //const isFirstRender = React.useRef(true);
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);
  const [filteringIndex, setFilteringIndex] = React.useState<number>(-1);
  const sectionListRef = React.useRef<SectionList<any>>(null);
  let blockUpdateIndex = false;
  let tabContainerMeasurements: LayoutRectangle;
  const tabsMeasurements: ITabsLayoutRectangle = {};
  const WindowWidth = Dimensions.get('window').width;
  const { sections, renderTab, tabBarStyle, scrollToLocationOffset } = sectionListData;
  const prepareSections = sections.map((item, index) => ({ ...item, index }));
  const itemsPerSectionToDisplay =
    filteringIndex !== -1
      ? prepareSections.filter((_, index) => {
          return index === currentIndex;
        })
      : prepareSections;

  const getScrollAmount = (position: number): number => {
    const pageOffset = 0;

    const containerWidth = WindowWidth;
    const tabWidth = tabsMeasurements[position] ? tabsMeasurements[position].width : null;
    const nextTabMeasurements = tabsMeasurements[position + 1];
    const nextTabWidth = (nextTabMeasurements && nextTabMeasurements.width) || 0;
    const tabOffset = tabsMeasurements[position] ? tabsMeasurements[position].left : null;
    if (tabWidth && tabOffset) {
      let newScrollX = tabOffset;

      newScrollX -=
        nextTabWidth !== 0
          ? (containerWidth - (1 - pageOffset) * tabWidth - nextTabWidth) / 2
          : (containerWidth - (1 - pageOffset) * tabWidth) / 2;
      newScrollX = newScrollX >= 0 ? newScrollX : 0;

      const rightBoundScroll = Math.max(tabContainerMeasurements.width - containerWidth, 0);

      newScrollX = newScrollX > rightBoundScroll ? rightBoundScroll : newScrollX;
      return newScrollX;
    } else {
      return 0;
    }
  };

  const TabBar = (tabData: TabBarProps): JSX.Element => {
    const onTabContainerLayout = (e: LayoutChangeEvent): void => {
      tabContainerMeasurements = e.nativeEvent.layout;
    };

    const onTabLayout = (key: number) => (event: LayoutChangeEvent) => {
      const { x, width, height } = event.nativeEvent.layout;
      tabsMeasurements[key] = {
        left: x,
        right: x + width,
        width,
        height,
      };
    };

    const renderTab = (section: SectionListData<any>, key: number) => {
      const isActive: boolean = tabData.currentIndex === key;

      return (
        <TouchableOpacity onPress={() => tabData.onPress(key)} key={key} onLayout={onTabLayout(key)}>
          {tabData.renderTab({ isActive, ...section })}
        </TouchableOpacity>
      );
    };

    /*React.useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
      } else {
        if (scrollViewRef.current) {
          scrollViewRef.current.scrollTo({
            x: getScrollAmount(),
            animated: true,
          });
        }
      }
    }, [tabData]);*/

    return (
      <View style={[{ width: WindowWidth }, tabData.tabBarStyle]}>
        <ScrollView
          ref={scrollViewRef}
          showsHorizontalScrollIndicator={false}
          horizontal
          contentContainerStyle={{ flexDirection: 'row' }}
        >
          <View onLayout={onTabContainerLayout} style={[{ flexDirection: 'row' }]}>
            {tabData.sections.map((section, index) => renderTab(section, index))}
          </View>
        </ScrollView>
      </View>
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <TabBar
        sections={prepareSections}
        renderTab={renderTab}
        tabBarStyle={tabBarStyle}
        currentIndex={currentIndex}
        onPress={(index: number) => {
          setCurrentIndex(index);
          blockUpdateIndex = true;

          const sectionList = sectionListRef.current;
          if (sectionList && sectionList.scrollToLocation) {
            sectionList.scrollToLocation({
              animated: true,
              itemIndex: 0,
              viewOffset: scrollToLocationOffset || 0,
              sectionIndex: 0,
            });
          }
          setFilteringIndex(filteringIndex === index ? -1 : index);
        }}
      />

      <SectionList
        {...sectionListData}
        sections={itemsPerSectionToDisplay}
        onViewableItemsChanged={({ viewableItems }) => {
          if (!blockUpdateIndex && viewableItems[0]) {
            const viewableItemsCurrentIndex = viewableItems[0].section.index;
            if (viewableItemsCurrentIndex === filteringIndex) {
              if (scrollViewRef.current) {
                scrollViewRef.current.scrollTo({
                  animated: true,
                  x: getScrollAmount(viewableItemsCurrentIndex),
                });
              }
            }
            if (filteringIndex === -1 && currentIndex !== viewableItemsCurrentIndex) {
              setCurrentIndex(viewableItemsCurrentIndex);
              if (scrollViewRef.current) {
                scrollViewRef.current.scrollTo({
                  animated: true,
                  x: getScrollAmount(viewableItemsCurrentIndex),
                });
              }
            }
          }
        }}
        viewabilityConfig={{
          minimumViewTime: 10,
          itemVisiblePercentThreshold: 10,
        }}
        ref={sectionListRef}
        onMomentumScrollEnd={() => (blockUpdateIndex = false)}
        renderSectionHeader={
          filteringIndex === -1
            ? ({ section: { title } }) => (
                <View>
                  <Text style={[styles.sectionHeaderText, { color: sectionListData.color }]}>{title}</Text>
                </View>
              )
            : undefined
        }
      />
    </View>
  );
};

import React from 'react';
import IzipayFormProps from 'types/components/IzipayFormProps';
import OrderContext from 'contexts/OrderContext';
import { storeIzipayPayment } from 'services/paymentsService';
import { SDK_IZIPAY } from '@env';

export default ({
  izipayPaymentData,
  orderId,
  token,
  setIsLoading,
  setResultPayment,
}: IzipayFormProps): JSX.Element => {
  const { setIzipayTransactionId } = React.useContext(OrderContext);

  const callbackResponsePayment = async (response: any) => {
    const success = response.code === '00';

    if (success) {
      setIzipayTransactionId(null);
      await storeIzipayPayment(
        {
          currency: '-',
          amount: 0,
          orderNumber: '-',
          codeAuth: '-',
          signature: '-',
          ...response,
        },
        orderId,
      );
    }
    setResultPayment({ success, response: response.message });
  };

  React.useEffect(() => {
    setIsLoading(true);
    const script = document.createElement('script');
    script.src = SDK_IZIPAY;
    script.async = true;
    script.onload = () => {
      try {
        const izipayInstance = new (window as any).Izipay({ config: izipayPaymentData });
        izipayInstance.LoadForm({
          authorization: token,
          keyRSA: 'RSA',
          callbackResponse: callbackResponsePayment,
        });
        setIsLoading(false);
      } catch (error: any) {
        console.error(error.message, error.Errors, error.date);
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [SDK_IZIPAY, izipayPaymentData, token]);

  return <div id="iframe-payment-izipay" />;
};

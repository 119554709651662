import React from 'react';
import { Text, TouchableOpacity, View, Platform } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import ProductItem from 'components/ProductItem';
import CustomTabBarSectionList from './CustomTabBarSectionList';
import ProductListProps from 'types/components/ProductsListProps';
import Product from 'types/models/Product';
import OrderContext from 'contexts/OrderContext';
import AppContext from 'contexts/AppContext';
import { styles } from 'styles/components/productsList';
import { greenColor } from 'styles/global';
import { productIsEmpty } from 'utils/products';

export default ({ commerce, navigation }: ProductListProps): JSX.Element => {
  const { t } = useTranslation();
  const route = useRoute();
  const [organizedCategories, setOrganizedCategories] = React.useState<any[]>([]);
  const { setCurrentProduct, currentShoppingCart, setCurrentShoppingCart, currentCommerce } =
    React.useContext(OrderContext);
  const { currentVenue, pairedWithClover } = React.useContext(AppContext);

  React.useEffect(() => {
    const categories: any[] = [];
    if (commerce.products && commerce.products.length > 0) {
      commerce.products.forEach((product: Product) => {
        if (!product.category) return;
        const productCategory = product.category.description;
        const category = categories.find((category) => category.title === productCategory);
        if (category) {
          category.data.push(product);
        } else {
          categories.push({
            title: productCategory,
            data: [product],
            requiresIdentification: product.category.requiresIdentification,
          });
        }
      });
    }
    setOrganizedCategories(categories);
  }, [commerce, commerce.products]);

  const goToProductDetails = async (id: string) => {
    const product = commerce.products.find((product) => product.id === id);
    if (product) {
      setCurrentProduct(product);
      navigation.navigate(
        'ProductDetailsScreen',
        Platform.OS === 'web'
          ? {
              venueCode: currentVenue?.code,
              commerceCode: currentCommerce?.code,
              productName: product.name.replace(/ /g, '-'),
            }
          : undefined,
      );
    }
  };

  const checkProductInShoppingCart = (product: Product): number => {
    if (!currentShoppingCart || currentShoppingCart.detail.length === 0) {
      return 0;
    } else {
      const productIndex = currentShoppingCart.detail.findIndex((item) => item.product.id === product.id);
      if (productIndex !== -1) {
        return currentShoppingCart.detail[productIndex].quantity;
      }
    }
    return 0;
  };

  const onChangeQuantity = (id: string, quantity: number) => {
    if (!currentShoppingCart) return;
    const detailItem = currentShoppingCart.detail.find((item) => item.product.id === id);
    if (detailItem) {
      detailItem.quantity = quantity;
    }
    setCurrentShoppingCart({ ...currentShoppingCart });
  };

  const onDeleteProduct = (id: string) => {
    if (currentShoppingCart) {
      currentShoppingCart.detail = currentShoppingCart.detail.filter((item) => item.product.id !== id);
      setCurrentShoppingCart({ ...currentShoppingCart });
    }
  };

  const addProductToShoppingCart = async (productItem: Product): Promise<void> => {
    if (!currentCommerce) return;
    if (productItem.isClothing) {
      goToProductDetails(productItem.id);
      return;
    }
    let shoppingCart = currentShoppingCart ?? null;
    if (shoppingCart && shoppingCart.commerce.id === currentCommerce.id) {
      //Si currentShoppingCart fue seteado, busca el producto. Si no lo encuentra lo agrega.
      const detailItem = shoppingCart.detail.find((item) => item.product.id === productItem.id);
      if (!detailItem) {
        shoppingCart.detail.push({ product: productItem, quantity: 1 });
      }
    } else {
      //Si currentShoppingCart no fue seteado o el comercio del carrito actual no coincide con el comercio del producto, se vacia el carrito
      shoppingCart = {
        commerce: { id: currentCommerce.id, code: currentCommerce.code, name: currentCommerce.name },
        detail: [{ product: productItem, quantity: 1 }],
        processed: false,
        paid: false,
        phone: '',
        isCash: false,
      };
    }
    setCurrentShoppingCart({ ...shoppingCart });
  };

  return (
    <View style={[styles.container, pairedWithClover && { marginBottom: 220 }]}>
      <CustomTabBarSectionList
        sections={organizedCategories}
        keyExtractor={(item: Product) => item.id}
        stickySectionHeadersEnabled={false}
        tabBarStyle={pairedWithClover ? styles.tabBarTotem : styles.tabBar}
        renderTab={({ title, isActive }: any) => (
          <View
            style={[
              styles.tabContainer,
              pairedWithClover && { paddingRight: 16, paddingBottom: 10 },
              isActive && [
                styles.activeItem,
                {
                  color: currentVenue?.appColor ?? greenColor,
                  borderBottomColor: currentVenue?.appColor ?? greenColor,
                },
              ],
            ]}
          >
            <Text
              style={[
                styles.tabText,
                pairedWithClover
                  ? {
                      fontSize: 32,
                      fontWeight: '500',
                      lineHeight: 20,
                      textAlign: 'center',
                      color: currentVenue?.appColor,
                    }
                  : { color: isActive && !pairedWithClover ? currentVenue?.appColor ?? greenColor : '#9e9e9e' },
              ]}
            >
              {title}
            </Text>
          </View>
        )}
        renderItem={(item: any) => (
          <TouchableOpacity
            key={item.item.index}
            onPress={() => goToProductDetails(item.item.id)}
            disabled={productIsEmpty(item.item)}
            style={[
              productIsEmpty(item.item) && { opacity: 0.5 },
              Platform.OS === 'ios' && {
                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 3.84,
              },
            ]}
          >
            <ProductItem
              product={item.item}
              isForCommerceListing={route.name === 'CommerceScreen'}
              showControlQuantity={true}
              initialQuantity={checkProductInShoppingCart(item.item)}
              onChangeQuantity={onChangeQuantity}
              onDeleteProduct={onDeleteProduct}
              addToCart={addProductToShoppingCart}
            />
            {productIsEmpty(item.item) && <Text style={styles.noStockMessage}>{t('no-stock')}</Text>}
          </TouchableOpacity>
        )}
        color={currentVenue?.appColor ?? greenColor}
      />
    </View>
  );
};
